import Vue from 'vue';
import VueRouter from 'vue-router';
import { UserRole } from '~/db_types/swagger_types';
import { AssemblyTemplateSharedRoutes, HardwareListSharedRoutes, HardwareListTemplateSharedRoutes, NonnominalSharedRoutes, OrganizationSharedRoutes, UserSharedRoutes } from '~/nasa_ui/router';
import { AdminSharedRoutes } from '~/nasa_ui/router/admin';
import { CosmicUserSupportContext, UserPermission } from '~/nasa_ui/types';
import { RouterLogUtility } from '../utils/RouterLogUtility';
Vue.use(VueRouter);
// Delivery matrix
const DeliveryMatrixRoutes = [
    {
        path: `/delivery_matrix`,
        name: 'DeliveryMatrixView',
        component: () => import(/* webpackChunkName: "DeliveryMatrix" */ '../views/DeliveryMatrixView/DeliveryMatrixView.vue'),
        meta: {
            displayName: 'Delivery matrix',
            description: 'The Delivery Matrix is a line item focused view of Delivery orders',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
// Delivery order routes
const DeliveryOrderRoutes = [
    {
        path: `/delivery_orders/search`,
        name: 'DeliveryOrderSearchView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderSearchView/DeliveryOrderSearchView.vue'),
        meta: {
            displayName: 'Delivery order',
            description: 'Delivery orders search',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [CosmicUserSupportContext.ESOC],
            userRoles: []
        }
    },
    {
        path: `/delivery_orders/manage/:id`,
        name: 'DeliveryOrderManageView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderManageView/DeliveryOrderManageView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery orders manage',
            description: 'Delivery orders manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.LOGISTICS_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/delivery_orders/create`,
        name: 'DeliveryOrderCreateView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderCreateView/DeliveryOrderCreateView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery orders creation',
            description: 'Delivery orders creation',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.LOGISTICS_ADMIN],
            userRoles: []
        }
    }
];
// Logistic routes
const LogisticsRoutes = [
    {
        path: `/item_listings`,
        name: 'ItemListingsView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/ItemListingsView/ItemListingsView.vue'),
        meta: {
            displayName: 'Item listings',
            description: 'List of items -smiley-',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [CosmicUserSupportContext.ESOC],
            userRoles: []
        }
    },
    {
        path: `/mega_allocation/:id?`,
        name: 'MegaAllocationView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/MegaAllocationView/MegaAllocationView.vue'),
        meta: {
            displayName: 'MEGA Allocation',
            description: '',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [CosmicUserSupportContext.ESOC],
            userRoles: []
        }
    }
];
export const ReportingRoutes = [
    {
        path: `/reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            displayName: 'Reporting search',
            description: 'Listing of every available COSMIC Logistics report.',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/full_parts_list`,
        name: 'FullPartReferenceReport',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FullPartReferenceReport/FullPartReferenceReport.vue'),
        meta: {
            displayName: 'Full parts list',
            description: 'Full list of all parts',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/mega_deliveries/:id?`,
        name: 'MegaDeliveriesReport',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/MegaDeliveriesReport/MegaDeliveriesReport.vue'),
        meta: {
            displayName: 'MEGA deliveries',
            description: 'MEGA centric view of estimated completion dates',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
const ALL_ROUTES = [
    {
        path: `/`,
        name: 'HomeView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/HomeView/HomeView.vue'),
        meta: {
            displayName: 'Logistics Home',
            description: 'Logistics home page',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/approval_templates/manage`,
        name: 'ChangeSetTemplatesManageView',
        component: () => import(
        /* webpackChunkName: "ChangeSets" */ '~/nasa_ui/views/ChangeSetTemplatesManageView/ChangeSetTemplatesManageView.vue'),
        meta: {
            displayName: `Approval Template manage`,
            description: 'Allows admin users to manage the approval queue for change set backed fields.',
            permissions: [],
            supportContexts: [],
            userRoles: [UserRole.ADMIN, UserRole.SYSTEM]
        }
    },
    ...AdminSharedRoutes,
    ...AssemblyTemplateSharedRoutes,
    ...DeliveryMatrixRoutes,
    ...DeliveryOrderRoutes,
    ...HardwareListSharedRoutes,
    ...HardwareListTemplateSharedRoutes,
    ...LogisticsRoutes,
    ...OrganizationSharedRoutes,
    ...ReportingRoutes,
    ...UserSharedRoutes,
    ...NonnominalSharedRoutes // needs to be last
];
export default new RouterLogUtility(VueRouter, ALL_ROUTES);
